import React from 'react';

import {
  ISubInventory,
  IInventoryChannel,
  IInventoryData
} from '../../../../../interfaces/IInventoryItem';
import InnerAccordion from '../../../../../components/InnerAccordion';
import SubinventoryList from '../SubinventoryList';
import { IUserPermissions } from '../../../../../interfaces/IAuthState';

const ChannelList: React.FC<{
  productCode: string;
  productDescription: string;
  productIsVirtual: boolean;
  channels: IInventoryChannel[];
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
  isDeleted: boolean;
  allowStockChange?: boolean;
  permissions: IUserPermissions;
}> = ({
  productCode,
  productDescription,
  productIsVirtual,
  channels,
  inventoryData,
  setInventoryData,
  isDeleted,
  allowStockChange,
  permissions
}) => {
  const getTotalItems = (ch: IInventoryChannel) =>
    ch.subinventories.reduce(
      (total: number, sb: ISubInventory) => total + Number(sb.stockOnHand),
      0
    );

  return (
    <>
      {channels.map((channel: IInventoryChannel) => (
        <InnerAccordion
          key={channel.channelId}
          title={`${channel.channelId} - ${channel.channelName}`}
          description={{
            code: 'Total Stock',
            value: getTotalItems(channel).toString()
          }}
        >
          <h5 className='inner-accordion__details__subtitle '>
            Subinventories
          </h5>
          <SubinventoryList
            productCode={productCode}
            productDescription={productDescription}
            productIsVirtual={productIsVirtual}
            channel={channel.channelName}
            subinventories={channel.subinventories}
            inventoryData={inventoryData}
            setInventoryData={setInventoryData}
            isDeleted={isDeleted}
            allowStockChange={allowStockChange}
            permissions={permissions}
          />
        </InnerAccordion>
      ))}
    </>
  );
};

ChannelList.defaultProps = {
  allowStockChange: false
};

export default ChannelList;
