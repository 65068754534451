import environment from '../environment';

export default {
  Auth: {
    // REQUIRED - Amazon Cognito Region
    region: environment.awsRegion,
    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: environment.userPoolId,
    // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: environment.userPoolClientId,
    // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
    // mandatorySignIn: false,

    oauth: {
      domain: environment.cognitoDomain,

      scope: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],

      redirectSignIn: environment.redirectSignIn,

      redirectSignOut: environment.redirectSignOut,

      responseType: 'code', // or token

      // optional, for Cognito hosted ui specified options
      options: {
        // Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag: true
      }
    }

    /* // OPTIONAL - Configuration for cookie storage
    // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
    cookieStorage: {
      // REQUIRED - Cookie domain (only required if cookieStorage is provided)
      domain: '.yourdomain.com',
      // OPTIONAL - Cookie path
      path: '/',
      // OPTIONAL - Cookie expiration in days
      expires: 365,
      // OPTIONAL - Cookie secure flag
      // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
      secure: true
    },

    // OPTIONAL - customized storage object
    storage: new MyStorage(),

    // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    authenticationFlowType: 'USER_PASSWORD_AUTH' */
  }
};
