import React from 'react';
import { Box } from '@mui/material';

import {
  dateHourFormatter,
  poundFormatter,
  boolFormatter
} from '../../../../../utils';
import {
  ISubInventory,
  IInventoryData
} from '../../../../../interfaces/IInventoryItem';
import ExpandRight from '../../../../../components/ExpandRight';
import SubinventoryModal from '../SubinventoryModal';
import PreorderReleaseModal from '../PreorderReleaseModal';
import { IUserPermissions } from '../../../../../interfaces/IAuthState';

const SubinventoryList: React.FC<{
  productCode: string;
  productDescription: string;
  productIsVirtual: boolean;
  channel: string;
  subinventories: ISubInventory[];
  inventoryData: IInventoryData;
  setInventoryData: CallableFunction;
  isDeleted: boolean;
  allowStockChange?: boolean;
  permissions: IUserPermissions;
}> = ({
  productCode,
  productDescription,
  productIsVirtual,
  channel,
  subinventories,
  inventoryData,
  setInventoryData,
  isDeleted,
  allowStockChange,
  permissions
}) => (
  <>
    {subinventories.map((subinventory: ISubInventory) => (
      <ExpandRight
        key={subinventory.subinventory}
        title={subinventory.subinventory}
        subtitle={[
          {
            code: 'Total Stock',
            value:
              subinventory.stockOnHand === null ||
              subinventory.stockOnHand === undefined
                ? '-'
                : subinventory.stockOnHand.toString()
          }
        ]}
        subinventoryEntries={[
          {
            code: 'Stock on hand',
            value:
              subinventory.stockOnHand === null ||
              subinventory.stockOnHand === undefined
                ? '-'
                : subinventory.stockOnHand.toString()
          },
          {
            code: 'Reserved',
            value:
              subinventory.reserved === null ||
              subinventory.reserved === undefined
                ? '-'
                : subinventory.reserved.toString()
          },
          {
            code: 'Can sell',
            value: boolFormatter(subinventory.canSell)
          },
          {
            code: 'Transfer price',
            value: poundFormatter(subinventory.transferPrice)
          },
          {
            code: 'Forward order',
            value: boolFormatter(subinventory.forwardOrder)
          },
          {
            code: 'Back order',
            value: boolFormatter(subinventory.backOrder)
          },
          {
            code: 'Release date',
            value: dateHourFormatter(subinventory.releaseDate)
          },
          {
            code: 'Preorder limit',
            value: (subinventory.preorderLimit || 0).toString()
          }
        ]}
      >
        {permissions.viewEditSubinventoryButton && !isDeleted && (
          <Box display='flex' justifyContent='space-between'>
            <SubinventoryModal
              productCode={productCode}
              productDescription={productDescription}
              productIsVirtual={productIsVirtual}
              channel={channel}
              subinventory={subinventory}
              inventoryData={inventoryData}
              setInventoryData={setInventoryData}
              allowStockChange={allowStockChange}
            />

            {permissions.viewReleasePreordersButton &&
              subinventory.canReleasePreorders && (
                <PreorderReleaseModal
                  productCode={productCode}
                  productDescription={productDescription}
                  channel={channel}
                  subinventory={subinventory}
                />
              )}
          </Box>
        )}
      </ExpandRight>
    ))}
  </>
);

SubinventoryList.defaultProps = {
  allowStockChange: false
};

export default SubinventoryList;
