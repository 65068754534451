import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => props.theme.colors.black};
  height: fit-content;
  display: flex;
  align-items: center;
  padding: 20px 0;

  .search-menu-row {
    display: flex;
    justify-content: space-between;

    .search-btn-wrapper {
      width: 500px;

      .clear-btn {
        margin-right: 10px;
      }
    }
  }

  h2 {
    color: ${(props) => props.theme.colors.white};
    font-size: 30px;
  }

  hr {
    margin: 20px 0;
  }
`;
