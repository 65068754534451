import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  background-color: ${(props) => props.theme.colors.lightGrey};
  padding: 20px;

  .report__grid {
    height: 350px;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
  .change_report {
    height: 365px;
  }

  .cell__actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }

  .MuiButton-root {
    min-width: 0px;
    width: 20px;
    height: 20px;
  }

  .row--highlight.row--disabled {
    background-color: ${(props) => props.theme.colors.lightGrey};
    color: ${(props) => props.theme.colors.grey};
  }

  .row--highlight.row--saved {
    background-color: #adcc5680; /* cav lightGreen with 50% opacity */
  }
`;
