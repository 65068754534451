import styled from 'styled-components';

export default styled.p`
  color: ${(props) => props.theme.colors.black};
  font-family: Vodafone Lt;

  span {
    font-weight: bolder;

    a {
      color: ${(props) => props.theme.colors.black};

      &:hover {
        color: ${(props) => props.theme.colors.vodafoneRed};
        text-decoration: none;
      }
    }
  }

  .tag {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }
`;
