import React from 'react';

import StyledInputLabel from './styles';

interface ILabelProps {
  id: string;
  control: JSX.Element;
  label: string;
  required?: boolean;
  classes?: string;
}

const Label: React.FC<ILabelProps> = ({
  id,
  control,
  label,
  required,
  classes
}) => (
  <StyledInputLabel htmlFor={id} className={classes}>
    {required ? (
      <p>
        {label}
        <span>*</span>
      </p>
    ) : (
      label
    )}
    {control}
  </StyledInputLabel>
);

Label.defaultProps = {
  required: false,
  classes: ''
};

export default Label;
