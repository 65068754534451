import React, { ReactNode } from 'react';
import { Select, MenuItem, SelectChangeEvent } from '@mui/material';

import StyledFormControl from './styles';
import Label from '../Label';

interface IControlledSelectProps {
  id: string;
  label: string;
  value: any;
  options: any[];
  handleChange: (event: SelectChangeEvent<any>, child: ReactNode) => void;
  classes?: string;
  required?: boolean;
}

const ControlledSelect: React.FC<IControlledSelectProps> = ({
  id,
  label,
  value,
  handleChange,
  options,
  classes,
  required
}) => (
  <Label
    id={id}
    control={
      <StyledFormControl variant={'outlined' as any}>
        <Select
          data-testid='select-testid'
          displayEmpty
          inputProps={{
            id,
            'data-testid': 'select-input-testid'
          }}
          value={value}
          onChange={handleChange}
        >
          {options.map((option: any) => (
            <MenuItem key={option.code} value={option}>
              {option.value}
            </MenuItem>
          ))}
        </Select>
      </StyledFormControl>
    }
    label={label}
    classes={classes}
    required={required}
  />
);

ControlledSelect.defaultProps = {
  classes: '',
  required: false
};

export default ControlledSelect;
