import styled from 'styled-components';

export default styled.div`
  padding-left: 40px;
  padding-top: 0px;

  .detail__grid {
    padding: 5px 45px 0px 5px;
    height: 330px;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
`;
