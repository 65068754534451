import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { GridApi } from 'ag-grid-community';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../../components/ControlledInput';
import environment from '../../../../../environment';
import PlusIcon from '../../../../../assets/icons/plus.svg';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';
import GenericModal from '../../../../../components/GenericModal';
import ProductCodeSearch from '../../../../../components/ProductCodeSearch';

interface IAddSkuGroupProps {
  rowData: ISkuGroup[];
  setRowData: any;
  gridApi: GridApi | undefined;
  gridLoading: boolean;
}

const AddSkuGroupModal: React.FC<IAddSkuGroupProps> = ({
  rowData,
  setRowData,
  gridApi,
  gridLoading
}) => {
  const [skuGroupName, setSkuGroupName] = useState('');
  const [skuGroupNameError, setSkuGroupNameError] = useState('');
  const [productCodes, setProductCodes] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const clearForm = () => {
    setSkuGroupName('');
    setSkuGroupNameError('');
    setProductCodes([]);
    setErrorMessage('');
  };

  const handleSkuGroupNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setSkuGroupName(name);

    if (isNull(name) || isUndefined(name) || name === '') {
      setSkuGroupNameError('Required field');
    } else if (
      rowData.some(
        (item: ISkuGroup) =>
          item.skuGroup.toLowerCase() === name.trim().toLowerCase()
      )
    ) {
      setSkuGroupNameError('Invalid field: SKU group name already exists');
    } else {
      setSkuGroupNameError('');
    }
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const res = await axios.post(
          `${environment.apiPath}skuGroup`,
          {
            name: skuGroupName.trim(),
            products: productCodes
          },
          {
            ...environment.params
          }
        );
        const rowNodes = [...rowData, res.data];
        setRowData(rowNodes);
        gridApi?.refreshCells({ rowNodes });
        toast.success(`Group ${skuGroupName} successfully created`);
      } catch (err: AxiosError | any) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
      } finally {
        setOpen(false);
        setLoading(false);
        clearForm();
      }
    };

  return (
    <GenericModal
      btnTitle='Add group'
      classes='btn--w-200-px btn--blue'
      endIcon={<img src={PlusIcon} alt='Add' />}
      disabled={gridLoading}
      clearForm={clearForm}
      formTitle='Create SKU Group'
      formError={!!skuGroupNameError || skuGroupName === ''}
      handleSubmit={handleSubmit}
    >
      <ControlledInput
        id='product-code'
        placeholder='Type SKU group name here'
        value={skuGroupName}
        handleChange={handleSkuGroupNameChange}
        error={skuGroupNameError}
        label='SKU group name'
        type='text'
        classes='label--w-100'
        required
      />
      <ProductCodeSearch
        productCodes={productCodes}
        setProductCodes={setProductCodes}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        maxLen={1399}
        classes='product-codes--ml-50 product-codes--justify-center'
      />
    </GenericModal>
  );
};

export default AddSkuGroupModal;
