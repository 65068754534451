import React, { ChangeEvent, SyntheticEvent, useState } from 'react';
import { Container } from '@mui/material';

import StyledSearchMenu from './styles';
import ControlledInput from '../../../../../components/ControlledInput';
import CustomButton from '../../../../../components/CustomButton';
import { IGenericEntry } from '../../../../../interfaces/IGenericSection';
import ControlledSelect from '../../../../../components/ControlledSelect';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import ProductCodeSearch from '../../../../../components/ProductCodeSearch';
import SkuGroupSearch from '../../../../../components/SkuGroupSearch';
import { isValid } from 'date-fns';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  serialNumber: string;
  setSerialNumber: CallableFunction;
  itemType: string;
  setItemType: CallableFunction;
  returnType: IGenericEntry;
  setReturnType: CallableFunction;
  returnTypes: IGenericEntry[];
  createDateOption: IGenericEntry;
  setCreateDateOption: CallableFunction;
  createDateOptions: IGenericEntry[];
  startDate: Date | null;
  setStartDate: CallableFunction;
  endDate: Date | null;
  setEndDate: CallableFunction;
  setDateRange: CallableFunction;
  handleSubmit: (event: SyntheticEvent) => void;
}

const ReturnsSearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  serialNumber,
  setSerialNumber,
  itemType,
  setItemType,
  returnType,
  setReturnType,
  returnTypes,
  createDateOption,
  setCreateDateOption,
  createDateOptions,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setDateRange,
  handleSubmit
}) => {
  const [skuGroupNames, setSkuGroupNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [invalidDate, setInvalidDate] = useState<boolean>(false);

  const clearSearchOptions = () => {
    setProductCodes([]);
    setSkuGroupNames([]);
    setSerialNumber('');
    setItemType('');
    setReturnType(returnTypes[0]);
    setStartDate(null);
    setEndDate(null);
    setCreateDateOption(createDateOptions[0]);
    setErrorMessage('');
    setInvalidDate(false);
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <h2>Search Returns</h2>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <SkuGroupSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              skuGroupNames={skuGroupNames}
              setSkuGroupNames={setSkuGroupNames}
              style={{ width: '100%' }}
            />
          </div>
          <br />
          <div className='search-menu-row'>
            <ProductCodeSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              style={{ width: '50%' }}
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledInput
              id='serial-number'
              placeholder='Type serial number here'
              value={serialNumber}
              handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                setSerialNumber(event.target.value.trim())
              }
              label='Serial number'
              type='text'
              classes='label--w-30'
            />
            <ControlledInput
              id='item-type'
              placeholder='Type item type here'
              value={itemType}
              handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                setItemType(event.target.value)
              }
              label='Item type'
              type='text'
              classes='label--w-30'
            />
            <ControlledSelect
              id='return-type'
              value={returnType}
              handleChange={(event: any) => setReturnType(event.target.value)}
              label='Return type'
              options={returnTypes}
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <ControlledSelect
              id='create-date-option'
              value={createDateOption}
              handleChange={(event: any) => {
                setCreateDateOption(event.target.value);
                setInvalidDate(false);
              }}
              label='Return date'
              options={createDateOptions}
              classes='label--w-30'
            />
            <CustomDatePicker
              id='start-date'
              value={startDate}
              handleChange={(event: any) => {
                setInvalidDate(!isValid(new Date(event)));
                setDateRange(true);
                setStartDate(event);
              }}
              label='Start date'
              classes='label--w-30'
            />
            <CustomDatePicker
              id='end-date'
              value={endDate}
              handleChange={(event: any) => {
                setInvalidDate(!isValid(new Date(event)));
                setDateRange(true);
                setEndDate(event);
              }}
              label='End date'
              classes='label--w-30'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey clear-btn'
              title='Clear'
              handleClick={() => clearSearchOptions()}
            />
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
              disabled={invalidDate}
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default ReturnsSearchMenu;
