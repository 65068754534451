import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import { IDeliveryGroup, IOrderItem } from '../../../../interfaces/IOrder';
import { getSerialNumberLabel } from '../../../../utils';
import GenericModal from '../../../../components/GenericModal';

interface IOrderItemError extends IOrderItem {
  serialIMEIError?: string;
  serialEIDError?: string;
  serialMACError?: string;
}

interface IDeliveryGroupError extends IDeliveryGroup {
  items: IOrderItemError[];
}

interface IDispatchProps {
  orderID: string;
  deliveryGroup: IDeliveryGroup;
  setDeliveryGroup: CallableFunction;
}

const DispatchModal: React.FC<IDispatchProps> = ({
  orderID,
  deliveryGroup,
  setDeliveryGroup
}) => {
  const [updatedGroup, setUpdatedGroup] =
    useState<IDeliveryGroupError>(deliveryGroup);
  const [consignmentNumberError, setConsignmentNumberError] = useState('');

  const handleIMEIChange = (item: IOrderItem, serialIMEI: string) => {
    let error = '';

    if (isNull(serialIMEI) || isUndefined(serialIMEI) || serialIMEI === '') {
      error = 'Required field';
    }

    setUpdatedGroup({
      ...updatedGroup,
      items: updatedGroup.items.map((itm: IOrderItem) =>
        itm.id === item.id
          ? {
              ...itm,
              serialIMEI,
              serialIMEIError: error
            }
          : itm
      )
    });
  };

  const handleMacAddressChange = (item: IOrderItem, serialMAC: string) => {
    let error = '';

    if (isNull(serialMAC) || isUndefined(serialMAC) || serialMAC === '') {
      error = 'Required field';
    }

    setUpdatedGroup({
      ...updatedGroup,
      items: updatedGroup.items.map((itm: IOrderItem) =>
        itm.id === item.id
          ? {
              ...itm,
              serialMAC,
              serialMACError: error
            }
          : itm
      )
    });
  };

  const handleEIDChange = (item: IOrderItem, serialEID: string) => {
    let error = '';

    if (isNull(serialEID) || isUndefined(serialEID) || serialEID === '') {
      error = 'Required field';
    }

    setUpdatedGroup({
      ...updatedGroup,
      items: updatedGroup.items.map((itm: IOrderItem) =>
        itm.id === item.id
          ? {
              ...itm,
              serialEID,
              serialEIDError: error
            }
          : itm
      )
    });
  };

  const handleConsignmentNumberChange = (
    event: ChangeEvent<HTMLInputElement>
  ) => {
    const consignmentNumber: string = event.target.value.trim();
    setUpdatedGroup({
      ...updatedGroup,
      consignmentNumber
    });

    if (
      isNull(consignmentNumber) ||
      isUndefined(consignmentNumber) ||
      consignmentNumber === ''
    ) {
      setConsignmentNumberError('Required field');
    } else {
      setConsignmentNumberError('');
    }
  };

  const clearForm = () => {
    setUpdatedGroup(deliveryGroup);
    setConsignmentNumberError('');
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        const res = await axios.post(
          `${environment.apiPath}dispatchDeliveryGroup`,
          {
            orderID,
            deliveryGroup: updatedGroup
          },
          { ...environment.params }
        );
        setDeliveryGroup(res.data);
        toast.success(
          `Delivery group ${deliveryGroup.number} successfully dispatched`
        );
      } catch (err: AxiosError | any) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
        clearForm();
      } finally {
        setLoading(false);
        setOpen(false);
      }
    };

  const formHasErrors = (): boolean =>
    (updatedGroup.details.dispatchMethodName === 'DPD' &&
      (!!consignmentNumberError || updatedGroup.consignmentNumber === '')) ||
    !!updatedGroup.items.find(
      (item: IOrderItemError) =>
        item.status === 'DISPATCH_INITIATED' &&
        item.serialControlled &&
        (item.serialIMEIError ||
          !item.serialIMEI ||
          (item.isRefurb && (item.serialEIDError || !item.serialEID)) ||
          (item.hasMacAddress && !item.serialMAC) ||
          item.serialIMEIError)
    );

  return (
    <GenericModal
      btnTitle='Dispatch'
      classes='btn--blue'
      clearForm={clearForm}
      formTitle={orderID}
      formSubtitle={
        <h3>
          {`${deliveryGroup.number} - ${deliveryGroup.details.dispatchMethodName},
          ${deliveryGroup.details.serviceName}`}
        </h3>
      }
      handleSubmit={handleSubmit}
      formError={formHasErrors()}
    >
      {updatedGroup.items
        .filter(
          (item: IOrderItemError) =>
            item.status === 'DISPATCH_INITIATED' && item.serialControlled
        )
        .map((item: IOrderItemError) => (
          <>
            <h4>
              {item.productCode} - {item.description} ({item.productType})
            </h4>
            <div className='column'>
              <ControlledInput
                id='IMEI'
                label={getSerialNumberLabel(item.productType)}
                type='text'
                value={item.serialIMEI || ''}
                placeholder={`Type ${getSerialNumberLabel(
                  item.productType
                )} here`}
                handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                  handleIMEIChange(item, event.target.value.trim())
                }
                error={item.serialIMEIError}
                required
              />
              <br />
              {item.hasMacAddress && (
                <ControlledInput
                  id='MAC'
                  label={getSerialNumberLabel(
                    item.productType,
                    item.hasMacAddress
                  )}
                  type='text'
                  value={item.serialMAC || ''}
                  placeholder={`Type ${getSerialNumberLabel(
                    item.productType,
                    item.hasMacAddress
                  )} here`}
                  handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleMacAddressChange(item, event.target.value.trim())
                  }
                  error={item.serialMACError}
                  required
                />
              )}
              {item.isRefurb && (
                <ControlledInput
                  id='EID'
                  label='EID'
                  type='text'
                  value={item.serialEID || ''}
                  placeholder='Type EID here'
                  handleChange={(event: ChangeEvent<HTMLInputElement>) =>
                    handleEIDChange(item, event.target.value.trim())
                  }
                  error={item.serialEIDError}
                  required
                />
              )}
              <hr />
            </div>
          </>
        ))}
      {updatedGroup.details.dispatchMethodName === 'DPD' && (
        <ControlledInput
          id='consignmentNumber'
          label='Consignment number'
          type='text'
          value={updatedGroup.consignmentNumber || ''}
          placeholder='Type consignment number here'
          handleChange={handleConsignmentNumberChange}
          error={consignmentNumberError}
          required
        />
      )}
    </GenericModal>
  );
};

export default DispatchModal;
