import styled from 'styled-components';

export default styled.div`
  display: flex;
  padding-top: 20px;

  h3 {
    font-size: 22px;
    margin: 8px 10px;
    color: ${(props) => props.theme.colors.black};
  }

  img {
    height: 40px;
  }
`;
