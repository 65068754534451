import React from 'react';
import { CircularProgress } from '@mui/material';

import StyledLoadingScreen from './styles';

const LoadingScreen: React.FC = () => (
  <StyledLoadingScreen>
    <CircularProgress className='circular-progress--color-red' />
    <p>Loading...</p>
  </StyledLoadingScreen>
);

export default LoadingScreen;
