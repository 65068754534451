import styled from 'styled-components';

export default styled('div')`
  display: flex;

  .MuiTypography-root {
    font-size: 20px;
    font-family: Vodafone Lt;
    color: ${(props) => props.theme.colors.white};
    padding-top: 27px;
  }

  .label--dark {
    color: ${(props) => props.theme.colors.black};
  }

  .MuiSwitch-root {
    padding: 5px;
    margin-top: 22px;

    .MuiSwitch-track {
      background-color: ${(props) => props.theme.colors.silverGrey};
      opacity: 0.9;
      border-radius: 20px;
    }

    .MuiSwitch-thumb {
      background-color: ${(props) => props.theme.colors.white};
    }

    .MuiSwitch-switchBase {
      &.Mui-checked {
        + .MuiSwitch-track {
          background-color: ${(props) => props.theme.colors.blue};
          opacity: 0.8;
        }

        .MuiSwitch-thumb {
          background-color: ${(props) => props.theme.colors.opacBlue};
        }
      }
    }
  }
`;
