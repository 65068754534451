import React from 'react';
import { Link, Tooltip } from '@mui/material';

import StyledGenericEntry from './styles';
import { IGenericEntry } from '../../../../interfaces/IGenericSection';

const GenericEntry: React.FC<IGenericEntry> = ({
  code,
  value,
  entryTag,
  link,
  component,
  noColon
}) => (
  <StyledGenericEntry>
    {code}
    {!noColon && ':'} {entryTag && <span className='tag'>{entryTag}</span>}
    <span>
      {link ? (
        <Tooltip
          title={
            <>
              <p>Click to follow link</p>
              <p>{link}</p>
            </>
          }
          arrow
        >
          <Link href={link} target='_blank' style={{ color: 'blue' }}>
            {value}
          </Link>
        </Tooltip>
      ) : (
        value
      )}
    </span>
    {component}
  </StyledGenericEntry>
);

export default GenericEntry;
