import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { poundFormatter, getSerialNumberLabel } from '../../../../../utils';
import ExpandRight from '../../../../../components/ExpandRight';
import { IOrderItem } from '../../../../../interfaces/IOrder';
import { IGenericEntry } from '../../../../../interfaces/IGenericSection';
import PriorityModal from '../../PriorityModal';
import { IUserPermissions } from '../../../../../interfaces/IAuthState';

const OrderItem: React.FC<{
  item: IOrderItem;
  subinventory: string;
  permissions: IUserPermissions;
}> = ({ item, subinventory, permissions }) => {
  const navigate = useNavigate();
  const [priority, setPriority] = useState(item.priority || 0);

  const redirectProduct = () => {
    navigate(
      `/inventory-management/product-catalogue?productCode=${item.productCode}`
    );
  };

  return (
    <>
      <ExpandRight
        key={item.id}
        title={`${item.productCode} - ${item.description} (${item.productType})`}
        subtitle={[
          {
            code: 'Status',
            value: item.statusDesc || item.status
          },
          {
            code: 'Sub-inventory',
            value: subinventory
          },
          {
            code: 'Trade-in dependency',
            value: item.tradeInDetails
              ? item.tradeInDetails.tradeInDependency.toString()
              : ''
          },
          {
            code: 'Priority',
            value: priority.toString(),
            component:
              permissions.viewPriorityModal && item.priorityChangeable ? (
                <PriorityModal
                  originalPrioriy={item.priority}
                  priority={priority}
                  setPriority={setPriority}
                  productCode={item.productCode}
                  reservationFullId={item.reservationFullId}
                />
              ) : null
          }
        ].filter((entry: IGenericEntry) => entry.value !== '')}
        titleTag={
          item.isAddOn ? '(Add-on) ' : item.isOverride ? '(Bag-override) ' : ''
        }
        description={[
          {
            code: getSerialNumberLabel(item.productType),
            value: item.serialIMEI || ''
          },
          {
            code: 'Serial MAC',
            value: item.serialMAC || ''
          },
          {
            code: 'Serial MAC',
            value: item.extraSerialNumber || ''
          },
          {
            code: 'Serial EID',
            value: item.serialEID || ''
          },
          {
            code: 'Fault Code',
            value: item.faultCode || ''
          },
          {
            code: 'Fault Code Name',
            value: item.faultCodeDesc || ''
          },
          {
            code: 'Repair Flag',
            value: item.repairFlag || ''
          }
        ].filter((entry: IGenericEntry) => entry.value !== '')}
        subinventoryEntries={[
          {
            code: 'Unit selling price',
            value: poundFormatter(item.unitSellingPrice)
          },
          {
            code: 'Line net selling price',
            value: poundFormatter(item.netSellingPrice)
          },
          {
            code: 'Line gross selling price',
            value: poundFormatter(item.grossSellingPrice)
          },
          {
            code: 'VAT amount',
            value: poundFormatter(item.vatAmount)
          },
          {
            code: 'VAT code',
            value: item.vatCode
          },
          {
            code: 'Priority code',
            value: item.shippingDependency
          },
          {
            code: 'Upgrade indicator',
            value: `(${item.upgradeIndicator}) ${item.upgradeIndicatorName}`
          }
        ]}
        redirectLink={redirectProduct}
      />
    </>
  );
};

export default OrderItem;
