import React from 'react';
import { Container } from '@mui/material';

import PageNotFoundIcon from '../../assets/icons/page-not-found-icon.png';
import StyledPageNotFound from './styles';
import CustomButton from '../CustomButton';
import ExpandRight from '../../assets/icons/expand-right.svg';
import { useNavigate } from 'react-router-dom';

const PageNotFound: React.FC = () => {
  const navigate = useNavigate();

  return (
    <StyledPageNotFound>
      <Container>
        <div className='textContainer'>
          <p>
            The <b>page</b> you are looking for <b>does not exist</b>.{' '}
          </p>
          <p>Please try using the navigation menu or go back to home page.</p>
          <CustomButton
            title='Home'
            classes='btn--w-100-px btn--red'
            handleClick={() => {
              navigate('/');
            }}
            endIcon={
              <img
                style={{ filter: 'brightness(100)' }}
                src={ExpandRight}
                alt='Go to home page'
              />
            }
          />
        </div>
        <img src={PageNotFoundIcon} alt='Page Not Found Icon' />
      </Container>
    </StyledPageNotFound>
  );
};

export default PageNotFound;
