import React from 'react';
import { Container } from '@mui/material';

import ForbiddenIcon from '../../assets/icons/forbidden-icon.png';
import StyledPageForbidden from './styles';

const PageForbidden: React.FC = () => (
  <StyledPageForbidden>
    <Container>
      <div className='textContainer'>
        <p>
          You <b>do not have access</b> to the page you requested.
        </p>
        <p>To view this page you need to sign in or use a different account.</p>
        <p>
          To request access to the platform please use&nbsp;
          <a target='_blank' rel='noreferrer' href='https://tobi.vodafone.com'>
            https://tobi.vodafone.com
          </a>
          .
        </p>
      </div>
      <img src={ForbiddenIcon} alt='Forbidden Icon' />
    </Container>
  </StyledPageForbidden>
);

export default PageForbidden;
