import React, { useState, ChangeEvent } from 'react';
import { Container } from '@mui/material';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';

import HeroProducts from '../../assets/heros/hero-products.png';
import HeroBanner from '../../components/HeroBanner';
import SearchBar from '../../components/SearchBar';
import NoResultsScreen from '../../components/NoResultsScreen';
import CustomPagination from '../../components/CustomPagination';
import CustomButton from '../../components/CustomButton';
import environment from '../../environment';
import { IGenericEntry } from '../../interfaces/IGenericSection';
import Order from './components/Order';
import { IOrder } from '../../interfaces/IOrder';

const searchOptions: IGenericEntry[] = [
  {
    code: 'orderId',
    value: 'Order ID'
  },
  {
    code: 'customerId',
    value: 'Customer ID'
  },
  {
    code: 'pickNumber',
    value: 'Pick number'
  }
];

const OrderManagement: React.FC<{
  permissions: any;
}> = ({ permissions }) => {
  const [orders, setOrders] = useState<IOrder[]>();
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [isUpoading, setIsUploading] = useState<boolean>(false);
  const pageSize = 5;
  const fileInput = React.useRef<any>();

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const uploadDPDTrackingFeed = async () => {
    setIsUploading(true);
    if (fileInput?.current?.files.length === 1) {
      const file = fileInput.current.files[0];
      try {
        await axios.post(
          `${environment.apiPath}bucketUpload/dpd/${file.name}`,
          file,
          {
            ...environment.params
          }
        );
        toast.success('File successfully uploaded');
      } catch (err: AxiosError | any) {
        toast.error(err.response.data.error);
      } finally {
        setIsUploading(false);
        if (fileInput.current) {
          fileInput.current.value = '';
        }
      }
    }
  };

  return (
    <>
      <HeroBanner title='Order Management' background={HeroProducts} />
      <SearchBar
        searchOptions={searchOptions}
        buttonTitle='Search for order'
        path='order'
        clearData={() => setOrders([])}
        handleSuccess={(dbOrders: IOrder[]) => setOrders(dbOrders)}
      />
      {permissions.viewUploadDpdDataButton && (
        <Container fixed>
          <CustomButton
            title='Upload DPD Feed'
            classes='btn--blue'
            handleClick={() => fileInput?.current?.click()}
            disabled={isUpoading}
          />
          <input
            type='file'
            ref={fileInput}
            style={{ display: 'none' }}
            onChange={uploadDPDTrackingFeed}
          />
        </Container>
      )}
      {orders && (
        <Container fixed>
          {orders.length === 0 && (
            <NoResultsScreen message='Search by order ID, customer ID or order pick number' />
          )}
          {orders
            .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
            .map((order: IOrder) => (
              <Order
                key={order.orderId}
                order={order}
                clearData={() => setOrders([])}
                permissions={permissions}
              />
            ))}
          {orders.length > pageSize && (
            <CustomPagination
              itemsCount={orders.length}
              pageSize={pageSize}
              handlePageChange={handlePageChange}
            />
          )}
        </Container>
      )}
    </>
  );
};

export default OrderManagement;
