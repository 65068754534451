import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  .main_report {
    height: 600px;
  }

  .report__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .report__actions--divider {
    display: flex;
    gap: 10px;
  }

  .report__grid {
    height: 600px;
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;
  }

  .row--highlight {
    background-color: #f9ca0080; /* cav yellow with 50% opacity */
  }
`;
