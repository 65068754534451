import React from 'react';

import GenericTitle from './components/GenericTitle/GenericTitle';
import GenericEntry from './components/GenericEntry/GenericEntry';
import {
  IGenericSection,
  IGenericEntry
} from '../../interfaces/IGenericSection';

const GenericSection: React.FC<IGenericSection> = ({ title, entries }) => (
  <>
    <GenericTitle img={title.img} altText={title.altText} title={title.title} />
    {entries.map((entry: IGenericEntry) => (
      <GenericEntry key={entry.code} code={entry.code} value={entry.value} />
    ))}
  </>
);

export default GenericSection;
