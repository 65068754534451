import styled from 'styled-components';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export default styled(DateTimePicker)`
  .MuiInputBase-root.Mui-error {
    border: 1px solid ${(props) => props.theme.colors.vodafoneRed};
  }

  .MuiInput-underline.Mui-error:after {
    transform: scaleX(0);
  }

  .MuiInput-underline:before {
    content: '';
    position: unset;
  }

  .MuiInput-underline:hover {
    border: 1px solid ${(props) => props.theme.colors.lightBlue};
  }

  .MuiInput-underline.Mui-focused {
    border: 2px solid ${(props) => props.theme.colors.lightBlue};
  }

  .MuiInput-underline.Mui-focused:after {
    transform: scaleX(0);
  }

  .MuiInputBase-root {
    background-color: ${(props) => props.theme.colors.white};
    width: 100%;
    height: 50px;
    font-family: Vodafone Rg;
    font-size: 20px;
    color: ${(props) => props.theme.colors.darkGrey};
    padding-left: 10px;
  }
`;
