import React from 'react';

import {
  dateHourFormatter,
  addressFormatter,
  poundFormatter
} from '../../../../utils';
import InfoIcon from '../../../../assets/icons/info.svg';
import UserIcon from '../../../../assets/icons/user.svg';
import SalesIcon from '../../../../assets/icons/sales.svg';
import GenericSection from '../../../../components/GenericSection';
import { IOrder } from '../../../../interfaces/IOrder';

const OrderSummary: React.FC<{ order: IOrder }> = ({ order }) => (
  <div className='row'>
    <div className='column'>
      <GenericSection
        title={{
          img: InfoIcon,
          altText: 'Info',
          title: 'Summary'
        }}
        entries={[
          {
            code: 'Origin',
            value: order.origin
          },
          {
            code: 'Dealer code',
            value: order.dealerCode
          },
          {
            code: 'Creation date',
            value: dateHourFormatter(order.dateTimeRaised)
          }
        ]}
      />
      <GenericSection
        title={{
          img: UserIcon,
          altText: 'User',
          title: 'Customer'
        }}
        entries={[
          {
            code: 'Account ID',
            value: order.customerId
          },
          {
            code: 'Segment',
            value: order.customerSegment
          },
          {
            code: 'Name',
            value: order.companyName ? order.companyName : '-'
          },
          {
            code: 'Email',
            value: order.customerEmail
          }
        ]}
      />
    </div>

    <div className='column column--w-50'>
      <GenericSection
        title={{
          img: SalesIcon,
          altText: 'Sales',
          title: 'Billing & Sales'
        }}
        entries={[
          {
            code: 'Contact',
            value: order.invoiceDetails.contact
              ? order.invoiceDetails.contact
              : ''
          },
          {
            code: 'Address',
            value: addressFormatter(order.invoiceDetails.address)
          },
          {
            code: 'Billing Account',
            value: order.billingArrangement
          },
          {
            code: 'Card payment amount',
            value: poundFormatter(order.cardPaymentAmount)
          },
          {
            code: 'Total net selling price',
            value: poundFormatter(order.totalNetSellingPrice)
          },
          {
            code: 'Total gross selling price',
            value: poundFormatter(order.totalGrossSellingPrice)
          },
          {
            code: 'Total VAT amount',
            value: poundFormatter(order.totalVatAmount)
          }
        ]}
      />
    </div>
  </div>
);

export default OrderSummary;
