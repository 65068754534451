import moment from 'moment';

import { IInvoiceAddress } from '../interfaces/IOrder';

export const dateFormatter = (date: string): string =>
  date ? moment.utc(date).local().format('LL') : '-';

export const hourFormatter = (date: string): string =>
  date ? moment.utc(date).local().format('LT') : '-';

export const dateHourFormatter = (date: string): string =>
  date ? `${dateFormatter(date)} ${hourFormatter(date)}` : '-';

export const dateHourFormatterReport = (date: string): string =>
  date ? moment.utc(date, 'YYYY-MM-DDTHHmmssZ').local().format('LLL') : '-';

export const addressFormatter = (address: IInvoiceAddress): string =>
  Object.values(address).filter(Boolean).join(', ');

export const poundFormatter = (value: number): string =>
  value === null || value === undefined ? '-' : `£ ${(value / 100).toFixed(2)}`;

export const boolFormatter = (value: boolean): string => (value ? 'Y' : 'N');

export const SuccessStates = ['active', 'accepted'];
export const CancelledState = 'cancelled';
export const CompleteState = 'complete';

const simList = ['Sim Only - PAYT', 'Sim - Contract', 'Sim Cards'];
const handsetList = [
  'Contract handsets',
  'Data Card - Contract',
  'Data Card - PayT',
  'DATACARD',
  'Handset Nearly New Contract',
  'Handset Nearly New PayT',
  'Landline',
  'NearlyNew DATA CARD - Contract',
  'NearlyNew DATA CARD - PayT',
  'NearlyNew PDA - Contract',
  'NearlyNew PDA - PayT',
  'NearlyNew WDA - Contract',
  'PayT',
  'WDA',
  'WDA - Contract',
  'WDA - PayT',
  'COMPANION DEVICE'
];

export const getSerialNumberLabel = (
  productType: string,
  hasMacAdress = false
): string => {
  if (simList.includes(productType)) {
    return 'ICCID';
  }

  if (handsetList.includes(productType)) {
    return 'IMEI';
  }

  if (hasMacAdress) {
    return 'Serial MAC';
  }

  return 'Serial number';
};
