import styled from 'styled-components';
import { InputLabel } from '@mui/material';

export default styled(InputLabel)`
  && {
    color: ${(props) => props.theme.colors.white};
    font-family: Vodafone Lt;
    font-size: 16px;
    display: flex;
    flex-direction: column;

    p {
      margin-top: 0;

      span {
        color: ${(props) => props.theme.colors.vodafoneRed};
        padding-left: 3px;
      }
    }
  }

  &.mt-22 {
    margin-top: 22px;
  }
  &.label--w-15 {
    width: 15%;
  }

  &.label--w-20 {
    width: 20%;
  }

  &.label--w-25 {
    width: 25%;
  }

  &.label--w-30 {
    width: 30%;
  }

  &.label--w-45 {
    width: 45%;
  }

  &.label--w-60 {
    width: 60%;
  }

  &.label--w-100 {
    width: 100%;
  }

  &.label--h-70 {
    height: 70px;
  }

  &.label--h-77 {
    height: 77px;
  }

  &.label--overflow-inherit {
    overflow: inherit;
  }

  .MuiIconButton-label {
    color: ${(props) => props.theme.colors.blue};
  }

  .MuiInputBase-root {
    border-radius: 0;
    margin-top: 4px;
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: ${(props) => props.theme.colors.lightBlue};
  }
`;
