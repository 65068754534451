import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../components/ControlledInput';
import environment from '../../../../environment';
import { IReservationId } from '../../../../interfaces/IOrder';
import EditIcon from '../../../../assets/icons/icons8-edit.svg';
import GenericModal from '../../../../components/GenericModal';

interface IPriorityProps {
  originalPrioriy: number;
  priority: number;
  setPriority: CallableFunction;
  productCode: string;
  reservationFullId: IReservationId | undefined;
}

const PriorityModal: React.FC<IPriorityProps> = ({
  originalPrioriy,
  priority,
  setPriority,
  productCode,
  reservationFullId
}) => {
  const [priorityError, setPriorityError] = useState('');

  const handlePriorityChange = (event: ChangeEvent<HTMLInputElement>) => {
    const priorityValue: number = parseInt(event.target.value, 10);
    setPriority(priorityValue);

    if (
      isNull(event.target.value) ||
      isUndefined(event.target.value) ||
      event.target.value === ''
    ) {
      setPriorityError('Required field');
    } else {
      setPriorityError('');
    }
  };

  const clearForm = () => {
    setPriority(originalPrioriy || 0);
    setPriorityError('');
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);
      try {
        await axios.post(
          `${environment.apiPath}modifyReservation`,
          {
            reservationFullId,
            priority
          },
          { ...environment.params }
        );
        toast.success('Priority successfully updated');
      } catch (err: AxiosError | any) {
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
        clearForm();
      } finally {
        setLoading(false);
        setOpen(false);
      }
    };

  return (
    <GenericModal
      iconBtn={EditIcon}
      formTitle={productCode}
      formSubtitle={<h3>{reservationFullId?.reservationId}</h3>}
      formError={!!priorityError}
      clearForm={clearForm}
      handleSubmit={handleSubmit}
      style={{ width: '16px', height: '16px' }}
    >
      <ControlledInput
        id='priority'
        label='Priority'
        type='number'
        value={priority}
        placeholder='Type new priority here'
        handleChange={handlePriorityChange}
        error={priorityError}
        required
      />
    </GenericModal>
  );
};

export default PriorityModal;
