import styled from 'styled-components';
import { FormControlLabel } from '@mui/material';

export default styled(FormControlLabel)`
  margin-left: -11px;

  .MuiCheckbox-root {
    color: ${(props) => props.theme.colors.blue} !important;
  }

  .MuiFormControlLabel-label {
    font-family: Vodafone Lt;
    font-size: 20px;
    color: ${(props) => props.theme.colors.white};

    &.Mui-disabled {
      color: ${(props) => props.theme.colors.shadowGrey};
    }
  }
`;
