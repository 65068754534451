import styled from 'styled-components';

export default styled('div')`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  border-top: 1px solid #bababa;

  .MuiContainer-root {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 420px;
    width: 1000px;

    .textContainer {
      display: flex;
      flex-direction: column;
      font-size: 24px;
      font-weight: 100;
    }
  }

  a {
    color: ${(props) => props.theme.colors.black};
    font-weight: bold;
  }

  a:hover {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }

  img {
    height: 100%;
  }
`;
