import React, { useRef, useState, useEffect } from 'react';
import { Chip, Button } from '@mui/material';
import TextField from '@mui/material/TextField';

import Label from '../Label';
import StyledAutoCompleteSearch from './styles';
import ViewMoreIcon from '../../assets/icons/view-more.svg';
import StyledAutocomplete from './acstyles';
import StyledFormControl from '../ControlledSelect/styles';
interface IAutoCompleteSearchProps {
  id: string;
  label: string;
  options: string[];
  searchTerms: string[];
  setSearchTerms: CallableFunction;
  placeholder?: string;
  classes?: string;
  style?: any;
}

const AutoCompleteSearch: React.FC<IAutoCompleteSearchProps> = ({
  id,
  label,
  options,
  searchTerms,
  setSearchTerms,
  placeholder,
  classes,
  style
}) => {
  const [filteredOptions, setFilteredOptions] = useState<string[]>(['']); // [options] - [searchterms]
  const [expandedChip, setExpandedChip] = useState(false);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);

  const checkSearchTermsOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expandedChip) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  const handleRemoveSearch = (term: string) => {
    setSearchTerms(searchTerms.filter((item) => item !== term));
    setFilteredOptions([...filteredOptions, term]);
  };

  const handleAddSearch = (term: string) => {
    if (options.includes(term)) {
      setSearchTerms([...searchTerms, term]);
    }
  };

  const sortOptions = (opts: string[]) =>
    opts.sort((a, b) => a.split(' - ')[1].localeCompare(b.split(' - ')[1]));

  useEffect(() => {
    setFilteredOptions(options.filter((item) => !searchTerms.includes(item)));
  }, [searchTerms, options]);

  useEffect(() => {
    const filtered = sortOptions(filteredOptions);

    if (filtered.length === 0) {
      filtered.push('No results found');
    }
  }, [filteredOptions]);

  useEffect(() => {
    checkSearchTermsOverflow(overflowingText.current);
  }, [searchTerms]);

  return (
    <StyledAutoCompleteSearch style={style} expandedChip={expandedChip}>
      <Label
        id='search-query'
        control={
          <StyledFormControl>
            <StyledAutocomplete
              data-testid='select-query-testid'
              options={filteredOptions}
              disabled={options.length === 0}
              getOptionLabel={(option: any) => option}
              onChange={(event: any, value: any) => {
                handleAddSearch(value);
              }}
              componentsProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'flip',
                      enabled: false
                    },
                    {
                      name: 'preventOverflow',
                      enabled: false
                    }
                  ]
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id={`${id}-textbox`}
                  placeholder={placeholder}
                  variant='outlined'
                  className='label--w-100 label--overflow-inherit'
                  fullWidth
                />
              )}
            />
          </StyledFormControl>
        }
        label={label}
        classes='label--w-100 label--overflow-inherit'
        required
      />
      <div
        ref={overflowingText}
        className={`autocomplete__searchterms-container ${classes}`}
      >
        {searchTerms.map((term: string) => (
          <Chip
            key={term}
            label={term}
            onDelete={() => handleRemoveSearch(term)}
          />
        ))}
      </div>
      {viewMoreBtnVisible && (
        <Button
          onClick={() => {
            setExpandedChip(!expandedChip);
          }}
          className={`view-more-button ${classes}`}
        >
          <span style={{ color: 'white' }}>
            {expandedChip ? 'View less' : 'View more'}
          </span>
          <img src={ViewMoreIcon} alt='ViewMore' />
        </Button>
      )}
    </StyledAutoCompleteSearch>
  );
};

AutoCompleteSearch.defaultProps = {
  placeholder: '',
  classes: '',
  style: null
};

export default AutoCompleteSearch;
