import styled from 'styled-components';
import { TextField } from '@mui/material';

export default styled(TextField)`
  .MuiInputBase-root {
    background-color: ${(props) => props.theme.colors.white};
    width: 100%;
    height: 50px;
    font-family: Vodafone Rg;
    font-size: 20px;
    color: ${(props) => props.theme.colors.darkGrey};
  }

  .MuiInputBase-input {
    display: flex;
    align-items: center;
    padding: 12px 14px;
    box-sizing: border-box;
  }

  .MuiFormHelperText-contained {
    margin: 0;
  }
`;
