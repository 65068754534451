import styled from 'styled-components';

export default styled.div`
  font-family: Vodafone Lt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 516px);

  .no-results-title {
    display: flex;
    align-items: center;

    h2 {
      font-size: 32px;
      color: ${(props) => props.theme.colors.vodafoneRed};
      padding: 20px;
    }
  }

  p {
    font-size: 22px;
    color: ${(props) => props.theme.colors.black};
  }
`;
