import React, { useState, SyntheticEvent, ChangeEvent } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isNull, isUndefined } from 'lodash';

import ControlledInput from '../../../../../components/ControlledInput';
import environment from '../../../../../environment';
import { IGenericEntry } from '../../../../../interfaces/IGenericSection';
import GenericEntry from '../../../../../components/GenericSection/components/GenericEntry';
import StyledSearchQuerySubtitle from './styles';
import GenericModal from '../../../../../components/GenericModal';

interface ISearchQueryProps {
  productCodes: string[];
  productDescription: string;
  stockPot: string;
  statusOperator: IGenericEntry;
  status: string;
  startDate: Date | null;
  endDate: Date | null;
  reportQueries: any[];
  setReportQueries: CallableFunction;
  createDateOption: string;
  invalidDate?: boolean;
}

interface IReportQuery {
  queryId: string;
  queryName: string;
  queryData: any;
}

const SearchQueryModal: React.FC<ISearchQueryProps> = ({
  productCodes,
  productDescription,
  stockPot,
  statusOperator,
  status,
  startDate,
  endDate,
  reportQueries,
  setReportQueries,
  createDateOption,
  invalidDate
}) => {
  const [queryName, setQueryName] = useState('');
  const [queryNameError, setQueryNameError] = useState('');

  const clearForm = () => {
    setQueryName('');
    setQueryNameError('');
  };

  const handleQueryNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setQueryName(name);

    if (isNull(name) || isUndefined(name) || name === '') {
      setQueryNameError('Required field');
    } else if (
      reportQueries.some(
        (query: IReportQuery) =>
          query.queryName.toLowerCase() === name.trim().toLowerCase()
      )
    ) {
      setQueryNameError('Invalid field: query name already exists');
    } else {
      setQueryNameError('');
    }
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      try {
        event.preventDefault();
        clearForm();
        setLoading(true);

        const res = await axios.post(
          `${environment.apiPath}reportQuery`,
          {
            queryName,
            queryData: {
              productCodes,
              descriptions: [productDescription],
              stockPot,
              status,
              statusOperator,
              createDateStart: startDate,
              createDateEnd: endDate,
              dateRange:
                createDateOption !== 'dateRange' ? createDateOption : ''
            }
          },
          { ...environment.params }
        );

        toast.success(`Query ${queryName} successfully saved`);
        setReportQueries([...reportQueries, res.data]);
      } catch (err: AxiosError | any) {
        clearForm();
        if (err.response) {
          toast.error(err.response.data.error);
        } else {
          toast.error(err.message);
        }
      } finally {
        setOpen(false);
        setLoading(false);
      }
    };

  const getCreateDateValue = (): string => {
    let createDate: string = startDate
      ? moment(startDate).format('DD/MM/YYYY')
      : '';
    if (endDate) {
      if (createDate) {
        createDate = createDate.concat(' - ');
      }
      createDate = createDate.concat(moment(endDate).format('DD/MM/YYYY'));
    }

    return createDate;
  };

  return (
    <GenericModal
      btnTitle='Save'
      disabled={invalidDate}
      clearForm={clearForm}
      formTitle='Save Reporting Query'
      formSubtitle={
        <StyledSearchQuerySubtitle>
          {productCodes.length > 0 && (
            <GenericEntry
              key='product-codes'
              code='Item SKUs'
              value={productCodes.join(', ')}
            />
          )}
          {productDescription && (
            <GenericEntry
              key='description'
              code='Item description'
              value={productDescription}
            />
          )}
          {stockPot && (
            <GenericEntry key='stock-pot' code='Stock pot' value={stockPot} />
          )}
          {status && (
            <GenericEntry
              key='status'
              code='Status'
              value={`(${statusOperator.value}) ${status}`}
            />
          )}
          {(startDate || endDate) && (
            <GenericEntry
              key='create-date'
              code='Create date'
              value={getCreateDateValue()}
            />
          )}
        </StyledSearchQuerySubtitle>
      }
      formError={!!queryNameError || queryName === ''}
      classes='btn--w-200-px btn--blue'
      handleSubmit={handleSubmit}
    >
      <ControlledInput
        id='query'
        label='Query name'
        type='text'
        value={queryName}
        placeholder='Type search query name here'
        handleChange={handleQueryNameChange}
        error={queryNameError}
        required
      />
    </GenericModal>
  );
};

export default SearchQueryModal;
