import styled from 'styled-components';

export default styled.div`
  &:last-child {
    .bar--dynamic {
      display: none;
    }
  }

  .trail-row {
    display: flex;
  }

  .trail-content:not(:last-child) {
    margin-bottom: 20px;
  }

  .circle {
    width: 60px;
    height: 60px;
    border: 3px solid
      ${(props: { color: string; height: number }) => props.color};
    border-radius: 50%;
    font-size: 16px;
    color: ${(props: { color: string; height: number }) => props.color};
    text-align: center;
    background: ${(props) => props.theme.colors.white};
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Vodafone Rg;
    text-transform: capitalize;
  }

  .bar--dynamic {
    height: ${(props: { color: string; height: number }) =>
      `${props.height - 80}px`};
    margin-top: ${(props: { color: string; height: number }) =>
      `${80 - props.height}px`};
  }
`;
