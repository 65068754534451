import React from 'react';

import { IGenericTitle } from '../../../../interfaces/IGenericSection';
import StyledGenericTitle from './styles';

const GenericTitle: React.FC<IGenericTitle> = ({ img, altText, title }) => (
  <StyledGenericTitle>
    <img src={img} alt={altText} />
    <h3>{title}</h3>
  </StyledGenericTitle>
);

export default GenericTitle;
