import styled from 'styled-components';

export default styled('div')`
  .live-warning {
    background-color: ${(props) => props.theme.colors.vodafoneRed};

    h1 {
      font-family: Vodafone Lt;
      color: ${(props) => props.theme.colors.white};
      font-weight: 100;

      span {
        font-weight: bold;
      }
    }
  }

  .home-banner {
    height: 320px;
    background-color: ${(props) => props.theme.colors.grey};
    width: 100%;

    .MuiContainer-root {
      display: flex;
      justify-content: space-around;
      align-items: center;
    }

    h1 {
      font-family: Vodafone Lt;
      margin: 0;
      color: ${(props) => props.theme.colors.black};
      font-size: 52px;
      font-weight: 100;

      span {
        font-weight: bold;
        display: inline-block;
        width: 100%;
      }
    }

    h2 {
      font-size: 20px;
      font-weight: 100;
      margin: 10px 20px 0 0;
    }

    img {
      height: 320px;
    }
  }
`;
