import React from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import fileDownload from 'js-file-download';

import environment from '../../../../../environment';
import DownloadIcon from '../../../../../assets/icons/download-report.svg';

// eslint-disable-next-line
export default ({ data }: any): JSX.Element => {
  const buttonClicked = async () => {
    try {
      const res = await axios.get(
        `${environment.apiPath}downloadReport?report=scheduled-reports&name=${data.filename}`,
        {
          headers: {
            Accept: 'application/json',
            ...environment.params.headers
          }
        }
      );

      const resS3 = await axios.get(res.data.s3url, {
        responseType: 'blob',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ...environment.params.headers
        }
      });

      fileDownload(resS3.data, data.filename);
    } catch (err: AxiosError | any) {
      toast.error(err.message);
    }
  };

  return (
    <IconButton
      tabIndex={0}
      aria-label='Download report'
      onClick={buttonClicked}
    >
      <img src={DownloadIcon} alt='Download' />
    </IconButton>
  );
};
