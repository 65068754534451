import styled from 'styled-components';

export default styled.div`
  padding: 20px;

  .detail__grid {
    height: 210px;
    font-size: 14px;
    font-family: Vodafone Lt;
  }
`;
