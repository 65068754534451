import styled from 'styled-components';
import { FormControl } from '@mui/material';

export default styled(FormControl)`
  .MuiInputBase-root {
    display: flex;
    background-color: ${(props) => props.theme.colors.lightGrey};
    font-family: Vodafone Rg;
    height: 50px;
    font-size: 20px;
    align-items: flex-end;

    .MuiSelect-select {
      color: ${(props) => props.theme.colors.darkGrey};
      font-size: 20px;
      font-family: Vodafone Rg;
      line-height: 26px;
      padding: 12px;
    }
  }
`;
