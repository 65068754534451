import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';

export default styled(ToastContainer)`
  .Toastify__toast--error {
    background: ${(props) => props.theme.colors.vodafoneRed};
  }

  .Toastify__toast-body {
    font-family: Vodafone Rg;
    font-size: 18px;
  }
`;
