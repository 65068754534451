import React, { SyntheticEvent, useState } from 'react';
import { Container, MenuItem, Select } from '@mui/material';

import StyledSearchMenu from './styles';
import CustomButton from '../../../../../components/CustomButton';
import Label from '../../../../../components/Label';
import StyledFormControl from '../../../../../components/ControlledSelect/styles';
import CustomSwitch from '../../../../../components/CustomSwitch';
import ProductCodeSearch from '../../../../../components/ProductCodeSearch';
import SkuGroupSearch from '../../../../../components/SkuGroupSearch';

interface ISearchMenuProps {
  productCodes: string[];
  setProductCodes: CallableFunction;
  stockPot: string;
  setStockPot: CallableFunction;
  subinventories: string[];
  weeksCover: string;
  setWeeksCover: CallableFunction;
  backorderFlag: boolean;
  setBackorderFlag: CallableFunction;
  handleSubmit: (event: SyntheticEvent) => void;
}

const InventorySearchMenu: React.FC<ISearchMenuProps> = ({
  productCodes,
  setProductCodes,
  stockPot,
  setStockPot,
  weeksCover,
  setWeeksCover,
  subinventories,
  backorderFlag,
  setBackorderFlag,
  handleSubmit
}) => {
  const weeksCoverFilter = [
    { name: '< 1', value: '1' },
    { name: '< 2', value: '2' },
    { name: 'All', value: 'all' }
  ];
  const [skuGroupNames, setSkuGroupNames] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const clearSearchOptions = () => {
    setProductCodes([]);
    setSkuGroupNames([]);
    setStockPot('');
    setWeeksCover('');
    setBackorderFlag(false);
    setErrorMessage('');
  };

  return (
    <StyledSearchMenu>
      <Container fixed>
        <h2>Search Inventory</h2>
        <form noValidate autoComplete='off' onSubmit={handleSubmit}>
          <div className='search-menu-row'>
            <SkuGroupSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              skuGroupNames={skuGroupNames}
              setSkuGroupNames={setSkuGroupNames}
              style={{ width: '100%' }}
            />
          </div>
          <br />
          <div className='search-menu-row'>
            <ProductCodeSearch
              productCodes={productCodes}
              setProductCodes={setProductCodes}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              style={{ width: '50%' }}
            />
            <Label
              id='sub-inventory'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={stockPot}
                    onChange={(event: any) => setStockPot(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select value
                    </MenuItem>
                    {subinventories.map((option: string) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Sub-inventory'
              classes='label--w-15 label--h-77'
            />
            <Label
              id='weeks-cover'
              control={
                <StyledFormControl variant={'outlined' as any}>
                  <Select
                    data-testid='select-testid'
                    displayEmpty
                    value={weeksCover}
                    onChange={(event: any) => setWeeksCover(event.target.value)}
                  >
                    <MenuItem key='placeholder' value='' disabled>
                      Select value
                    </MenuItem>
                    {weeksCoverFilter.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              }
              label='Weeks cover'
              classes='label--w-15 label--h-77'
            />
            <CustomSwitch
              checked={backorderFlag}
              handleChange={(event: any) =>
                setBackorderFlag(event.target.checked)
              }
              onLabel='BACKORDER'
              offLabel='ALL'
            />
          </div>
          <hr />
          <div className='search-menu-row'>
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--light-grey clear-btn'
              title='Clear'
              handleClick={() => clearSearchOptions()}
            />
            <CustomButton
              type='submit'
              classes='btn--w-200-px'
              title='Search'
            />
          </div>
        </form>
      </Container>
    </StyledSearchMenu>
  );
};

export default InventorySearchMenu;
