import styled from 'styled-components';

export default styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.black};
  opacity: 0.7;
  z-index: 10;

  p {
    font-family: Vodafone Rg;
    font-size: 22px;
    margin-left: 24px;
    color: white;
  }

  .circular-progress--color-red {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }
`;
