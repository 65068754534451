import React, { useEffect, useState } from 'react';
import { ICellRendererParams } from 'ag-grid-community';
import StyledStarRating from './styles';
interface IStarRatingProps {
  params: ICellRendererParams;
  label: string;
  limit?: number;
  disabled?: boolean;
  readOnly?: boolean;
  classes?: string;
}

const StarRating: React.FC<IStarRatingProps> = ({
  params,
  label,
  limit,
  disabled,
  readOnly,
  classes
}) => {
  const [rating, setRating] = useState<number>(Number(params.value));

  useEffect(() => {
    setRating(Number(params.value));
  }, [params]);

  return (
    <StyledStarRating
      className={classes}
      value={rating}
      max={limit}
      onChange={(event, newValue) => {
        if (newValue !== null) {
          (params.setValue ?? (() => {}))(String(newValue));
          setRating(newValue);
        }
      }}
      readOnly={readOnly}
      disabled={disabled}
      aria-label={label}
    />
  );
};

StarRating.defaultProps = {
  limit: 3,
  disabled: false,
  readOnly: false,
  classes: ''
};

export default StarRating;
