import React, { useState, ChangeEvent } from 'react';
import Container from '@mui/material/Container';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import HeroProducts from '../../../assets/heros/hero-products.png';
import ArrowLeft from '../../../assets/icons/arrow-left.svg';
import ArrowRight from '../../../assets/icons/arrow-right.svg';
import OrganizationIcon from '../../../assets/icons/organization.svg';
import HeroBanner from '../../../components/HeroBanner';
import SearchBar from '../../../components/SearchBar';
import CustomAccordion from '../../../components/CustomAccordion';
import CustomButton from '../../../components/CustomButton';
import CustomTooltip from '../../../components/CustomTooltip';
import CustomError from '../../../components/CustomError';
import { IGenericEntry } from '../../../interfaces/IGenericSection';
import ItemSummary from './components/ItemSummary';
import ChannelList from './components/ChannelList';
import {
  IInventoryData,
  IInventoryItem
} from '../../../interfaces/IInventoryItem';
import NoResultsScreen from '../../../components/NoResultsScreen';
import CustomPagination from '../../../components/CustomPagination';
import AuditTrail from './components/AuditTrail';
import GenericTitle from '../../../components/GenericSection/components/GenericTitle';
import environment from '../../../environment';
import LoadingScreen from '../../../components/LoadingScreen';
import moment from 'moment';

const searchOptions: IGenericEntry[] = [
  {
    code: 'productCode',
    value: 'Item SKU'
  },
  {
    code: 'description',
    value: 'Item description'
  }
];

const initialInventory: IInventoryData = {
  count: -1,
  allItems: true,
  items: []
};

const ProductCatalogue: React.FC<{
  permissions: any;
}> = ({ permissions }) => {
  const [contentIdx, setContentIdx] = useState(1);
  const [inventoryData, setInventoryData] =
    useState<IInventoryData>(initialInventory);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const pageSize = 5;
  const [lastUpdateDate, setLastUpdateDate] = useState<string>('');

  const handleSuccess = (data: IInventoryData) => {
    setPageNumber(1);
    setInventoryData(data);
  };

  const handlePageChange = (event: ChangeEvent<unknown>, page: number) => {
    setPageNumber(page);
  };

  const handleContentChange = (idx: number) => {
    setContentIdx(idx);
  };

  const clearInventory = () => {
    setInventoryData(initialInventory);
    setContentIdx(1);
  };

  const onAuditChange = (lastDate: string) => {
    setLastUpdateDate(lastDate);
  };

  const isValidFileName = (fileName: string) =>
    /^MasterDeviceList(?:_\w+)*\.xlsx$/.test(fileName);

  const loadChannels = async (it: IInventoryItem) => {
    setLoading(true);

    try {
      const res = await axios.get(
        `${environment.apiPath}product/${it.productCode}`,
        {
          ...environment.params
        }
      );

      setInventoryData({
        ...inventoryData,
        items: inventoryData.items.map((item: IInventoryItem) =>
          item.productCode === it.productCode
            ? {
                ...item,
                channels: res.data.channels
              }
            : item
        )
      });
      setLoading(false);
    } catch (err: AxiosError | any) {
      setLoading(false);
      toast.error(err.message);
    }
  };

  const renderDetails = (item: IInventoryItem) => {
    switch (contentIdx) {
      case 1:
        return (
          <>
            <ItemSummary item={item} />
            <GenericTitle
              img={OrganizationIcon}
              altText='Organization'
              title='Organization channels'
            />
            {(!item.channels || item.channels.length === 0) && (
              <CustomButton
                title='Load'
                classes='btn--blue btn--w-10'
                handleClick={() => loadChannels(item)}
              />
            )}
            {item.channels && item.channels.length > 0 && (
              <ChannelList
                productCode={item.productCode}
                productDescription={item.description}
                productIsVirtual={item.isVirtual}
                channels={item.channels}
                inventoryData={inventoryData}
                setInventoryData={setInventoryData}
                isDeleted={item.deleted}
                allowStockChange={permissions.changeStockLevel}
                permissions={permissions}
              />
            )}
            <div className='row row--justify-end'>
              <CustomButton
                title='Audit trail'
                endIcon={<img src={ArrowRight} alt='Arrow right' />}
                handleClick={() => handleContentChange(2)}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <AuditTrail
              productCode={item.productCode}
              onAuditChange={onAuditChange}
            />
            <div className='row'>
              <CustomButton
                title='Item details'
                classes='btn--grey'
                startIcon={<img src={ArrowLeft} alt='Arrow left' />}
                handleClick={() => handleContentChange(1)}
              />
            </div>
          </>
        );
      default:
        return (
          <div className='row row--justify-end'>
            <CustomButton
              title='Item details'
              endIcon={<img src={ArrowRight} alt='Arrow right' />}
              handleClick={() => handleContentChange(1)}
            />
          </div>
        );
    }
  };

  const fileInput = React.useRef<any>();

  const uploadMasterDeviceList = async () => {
    if (fileInput?.current?.files.length === 1) {
      const file = fileInput.current.files[0];
      const formData = new FormData();
      formData.append('file', file, file.name);

      if (!isValidFileName(file.name)) {
        toast.error(
          'Incorrect filename format. Expected format <MasterDeviceList_suffix.xlsx>'
        );
        fileInput.current.value = '';

        return;
      }

      const headers = {
        'content-type':
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ...environment.params.headers
      };

      try {
        await axios.post(
          `${environment.apiPath}masterDeviceUpload/${file.name}`,
          file,
          {
            headers
          }
        );
        toast.success('File successfully uploaded');
      } catch (err: AxiosError | any) {
        toast.error(err.response.data.error);
      }
    }
  };

  return (
    <>
      <HeroBanner title='Product Catalogue' background={HeroProducts} />
      <SearchBar
        searchOptions={searchOptions}
        buttonTitle='Search for item'
        path='product'
        clearData={clearInventory}
        handleSuccess={(data: IInventoryData) => handleSuccess(data)}
      />
      {permissions.viewUploadMasterDeviceListButton && (
        <Container fixed>
          <CustomTooltip title='Expected filename format: <MasterDeviceList_suffix.xlsx>'>
            <CustomButton
              title='Upload Master Device List'
              classes='btn--blue'
              handleClick={() => fileInput?.current?.click()}
            />
          </CustomTooltip>
          <input
            type='file'
            ref={fileInput}
            style={{ display: 'none' }}
            onChange={uploadMasterDeviceList}
          />
        </Container>
      )}

      <Container fixed>
        {!inventoryData.allItems && (
          <CustomError
            title='Too many items were found for this search!'
            description='If you want to see more results, try your search again with more specific keywords.'
          />
        )}
        {inventoryData.count === 0 && (
          <NoResultsScreen message='Search by item SKU or item description' />
        )}

        {inventoryData.items
          .slice((pageNumber - 1) * pageSize, pageNumber * pageSize)
          .map((item: IInventoryItem) => (
            <CustomAccordion
              key={item.productCode}
              status={item.status}
              isDeleted={item.deleted}
              primaryTitle={item.productCode}
              secondaryTitle={item.description}
              summaryEntries={[
                [
                  {
                    code: 'Item category',
                    value: item.category
                  },
                  {
                    code: 'Item type',
                    value: item.productType
                  },
                  {
                    code: 'Last updated',
                    value: item.lastUpdated
                      ? moment(item.lastUpdated).format('ll')
                      : '-'
                  }
                ]
              ]}
            >
              {renderDetails(item)}
            </CustomAccordion>
          ))}

        {inventoryData.count > pageSize && (
          <CustomPagination
            itemsCount={inventoryData.count}
            pageSize={pageSize}
            handlePageChange={handlePageChange}
          />
        )}
      </Container>
      {loading && <LoadingScreen />}
    </>
  );
};

export default ProductCatalogue;
