import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { LicenseManager } from 'ag-grid-enterprise';
import { datadogRum } from '@datadog/browser-rum';
import { BrowserRouter } from 'react-router-dom';

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import environment from './environment';

LicenseManager.setLicenseKey(
  'Using_this_{AG_Grid}_Enterprise_key_{AG-055999}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Vodafone_(UK,_Newbury)_(HQ)}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Cavendish}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Cavendish}_need_to_be_licensed___{Cavendish}_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{1_April_2025}____[v3]_[01]_MTc0MzQ2MjAwMDAwMA==6a45cdb9fc6ffa37a245e58477dccc49'
);

if (environment.rumApplicationId && environment.rumClientToken) {
  datadogRum.init({
    applicationId: environment.rumApplicationId
      ? environment.rumApplicationId
      : '',
    clientToken: environment.rumClientToken ? environment.rumClientToken : '',
    site: environment.rumSite,
    service: 'CavendishPortal',
    env: environment.stage,
    //  version: '1.0.0',
    sampleRate: 100,
    sessionReplaySampleRate: 100,
    trackResources: true,
    trackLongTasks: true,
    trackInteractions: true
  });
}

const rootElement = document.getElementById('root');

if (rootElement) {
  const root = createRoot(rootElement);
  root.render(
    <BrowserRouter>
      <App />
    </BrowserRouter>
  );
} else {
  console.log('Root element not found');
}

serviceWorker.unregister();
