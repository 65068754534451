import React from 'react';

import { IOrderItem } from '../../../../interfaces/IOrder';
import OrderItem from './OrderItem';
import { IUserPermissions } from '../../../../interfaces/IAuthState';

const OrderItemList: React.FC<{
  items: IOrderItem[];
  subinventory: string;
  virtualSubinventory: string;
  permissions: IUserPermissions;
}> = ({ items, subinventory, virtualSubinventory, permissions }) => (
  <>
    {items.map((item: IOrderItem) => (
      <OrderItem
        item={item}
        key={item.id}
        subinventory={item.isVirtual ? virtualSubinventory : subinventory}
        permissions={permissions}
      />
    ))}
  </>
);

export default OrderItemList;
