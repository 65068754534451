import React from 'react';
import { Container } from '@mui/material';

import StyledHomeBanner from './styles';
import HomeIcon from '../../assets/icons/home-icon.png';

const Home: React.FC = () => (
  <StyledHomeBanner>
    <div className='home-banner'>
      <Container>
        <div>
          <h1>
            Welcome to <span>Cavendish Portal!</span>
          </h1>
          <h2>
            Welcome to the new Vodafone UK physical order management platform, a
            tool designed to operate and track forward and reverse logistics.
          </h2>
        </div>
        <img src={HomeIcon} alt='Cavendish Home Icon' />
      </Container>
    </div>
  </StyledHomeBanner>
);

export default Home;
