import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  height: 100%;

  .MuiIconButton-root {
    padding: 3px 20px 0 0;
  }
`;
