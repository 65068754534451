import styled from 'styled-components';

export default styled.div`
  background-color: ${(props) => props.theme.colors.black};
  height: 120px;
  display: flex;
  align-items: center;

  form {
    display: flex;
    align-items: flex-end;
  }
`;
