import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import moment from 'moment';
import axios from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import HeroProducts from '../../../assets/heros/hero-products.png';
import HeroBanner from '../../../components/HeroBanner';
import StyledReport from './styles';
import environment from '../../../environment';
import ControlledInput from '../../../components/ControlledInput/ControlledInput';
import CustomButton from '../../../components/CustomButton';
import DownloadIcon from '../../../assets/icons/download.svg';
import StarRating from '../components/StarRating';
import TierChanges from './components/TierChanges';
import { IStores, ITierChanges } from '../interfaces/IStores';

const Forecasting: React.FC = () => {
  const [rowData, setRowData] = useState<IStores[]>([]);
  const [gridApi, setGridApi] = useState<GridApi>();
  const [quickFilter, setQuickFilter] = useState<string>('');
  const [tierChanges, setTierChanges] = useState<ITierChanges[]>([]);
  const fileInput = React.useRef<any>();

  const getStores = () => {
    axios
      .get(`${environment.apiPathForecasting}getStoreConfigItems`)
      .then((res: any) => {
        let rows: any = [];
        rows = rows.concat(
          res.data.rows.map((row: any[]) => _.zipObject(res.data.columns, row))
        );

        setRowData(rows);
      });
  };

  useEffect(() => {
    getStores();
  }, []);

  const uploadStoreTierTable = () => {
    if (fileInput?.current?.files.length === 1) {
      const file = fileInput.current.files[0];
      axios
        .post(
          `${environment.apiPathForecasting}processStoreTierFile?fileName=${file.name}`,
          file,
          {
            ...environment.params
          }
        )
        .then(
          (response) => {
            if (response.data.statusCode === 200) {
              toast.success(response.data.body);
              getStores();
            } else {
              if (response.data.body) {
                toast.error(response.data.body);
              } else {
                toast.error('An error occurred while processing the file.');
              }
            }
          },
          (err) => toast.error(err)
        );
      fileInput.current.value = '';
    }
  };

  const onGridReady = (params: GridReadyEvent) => {
    params.api.showLoadingOverlay();
    setGridApi(params.api);
  };

  const handleDataExport = () => {
    gridApi?.exportDataAsCsv({
      fileName: `Forecasting_Top_Stores${moment().format(
        'YYYYMMDD_HHmmss'
      )}.csv`
    });
  };

  const renderStars = (params: any) => {
    const cell = (
      <StarRating
        label='top-store-rating'
        params={params}
        readOnly={params.node.group || params.readOnly}
        classes={params.classes}
      />
    );
    if (params.value) {
      return cell;
    }

    return <span />;
  };

  const columnDefs = [
    {
      headerName: 'Tier',
      field: 'tier',
      enableRowGroup: true,
      sortable: true,
      minWidth: 95,
      cellRenderer: renderStars
    },
    {
      headerName: 'Store Code',
      field: 'id',
      sortable: true,
      minWidth: 70
    },
    {
      headerName: 'Store Name',
      field: 'name',
      minWidth: 250,
      sortable: true
    },
    {
      headerName: 'Store Type',
      field: 'paStore',
      enableRowGroup: true,
      sortable: true,
      minWidth: 60
    },
    {
      headerName: 'Address',
      field: 'address',
      sortable: true,
      minWidth: 100
    },
    {
      headerName: 'Postcode',
      field: 'postcode',
      minWidth: 150,
      sortable: true
    },
    {
      headerName: 'Region',
      field: 'region',
      enableRowGroup: true,
      sortable: true,
      minWidth: 65
    },
    {
      headerName: 'Division',
      field: 'division',
      enableRowGroup: true,
      sortable: true,
      minWidth: 100
    }
  ];

  return (
    <>
      <HeroBanner title='Store Configuration' background={HeroProducts} />
      <StyledReport fixed>
        <div className='report__actions'>
          <ControlledInput
            id='quick-filter'
            placeholder='Filter any column'
            value={quickFilter}
            handleChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setQuickFilter(event.target.value);
              gridApi?.setQuickFilter(event.target.value);
            }}
            label='Grid filter'
            type='text'
            classes='label--w-30'
          />
          <div className='report__actions--divider'>
            <TierChanges
              tierChanges={tierChanges}
              setTierChanges={setTierChanges}
              gridApi={gridApi}
              renderStars={renderStars}
            />
            <CustomButton
              title='Upload Store File'
              classes='btn--w-200-px btn--blue'
              handleClick={() => {
                fileInput?.current?.click();
              }}
            />
            <input
              type='file'
              ref={fileInput}
              style={{ display: 'none' }}
              onChange={uploadStoreTierTable}
            />
            <CustomButton
              type='button'
              classes='btn--w-200-px btn--black'
              title='Export'
              endIcon={<img src={DownloadIcon} alt='Download configuration' />}
              handleClick={handleDataExport}
            />
          </div>
        </div>
        <div className='main_report report__grid ag-theme-balham'>
          <AgGridReact
            rowHeight={30}
            rowData={rowData}
            pagination
            defaultColDef={{
              resizable: true,
              flex: 1,
              sortable: true
            }}
            getRowClass={(params) => {
              if (params.data) {
                if (tierChanges.find((obj) => obj.id === params.data.id)) {
                  return 'row--highlight';
                }
              }
            }}
            enableCellTextSelection
            onGridReady={onGridReady}
            suppressDragLeaveHidesColumns
            suppressRowClickSelection
            autoGroupColumnDef={{ minWidth: 200 }}
            getRowId={(params) => params.data.id}
            rowGroupPanelShow='always'
            excelStyles={[
              {
                id: 'stringType',
                dataType: 'String'
              }
            ]}
            columnDefs={columnDefs}
          />
        </div>
      </StyledReport>
    </>
  );
};

export default Forecasting;
