import React, { useState, SyntheticEvent, useEffect, ChangeEvent } from 'react';
import { MenuItem, Select, TextField } from '@mui/material';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { isNull, isUndefined } from 'lodash';

import CalendarIcon from '../../../../../assets/icons/calendar.svg';
import environment from '../../../../../environment';
import StyledFormControl from '../../../../../components/ControlledSelect/styles';
import CustomDatePicker from '../../../../../components/CustomDatePicker';
import Label from '../../../../../components/Label';
import GenericModal from '../../../../../components/GenericModal';
import ControlledInput from '../../../../../components/ControlledInput';
import { IScheduledReport } from '../../../../../interfaces/IScheduledReport';
import StyledAutocomplete from './styles';

const reportFrequencies: string[] = ['hourly', 'daily', 'weekly', 'monthly'];

interface IScheduleReportModalProps {
  rowData: IScheduledReport[];
  setRowData: CallableFunction;
}

const ScheduleReportModal: React.FC<IScheduleReportModalProps> = ({
  rowData,
  setRowData
}) => {
  const [reportName, setReportName] = useState('');
  const [reportNameError, setReportNameError] = useState('');
  const [query, setQuery] = useState<any>('');
  const [frequency, setFrequency] = useState(reportFrequencies[0]);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [reportQueries, setReportQueries] = useState([]);
  const [startDateError, setStartDateError] = useState(false);
  const [endDateError, setEndDateError] = useState(false);
  const [helperText, setHelperText] = useState('');

  const clearForm = () => {
    setQuery('');
    setFrequency(reportFrequencies[0]);
    setStartDate(null);
    setEndDate(null);
    setReportName('');
  };

  const handleReportNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setReportName(name);

    if (isNull(name) || isUndefined(name) || name === '') {
      setReportNameError('Required field');
    } else if (
      rowData.some(
        (item: IScheduledReport) =>
          item.reportName.toLowerCase() === name.trim().toLowerCase()
      )
    ) {
      setReportNameError('Report name already exists');
    } else {
      setReportNameError('');
    }
  };

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      try {
        const res = await axios.post(
          `${environment.apiPath}scheduledReport`,
          {
            reportName,
            queryId: query.queryId,
            frequency,
            startDate: startDate ? moment.utc(startDate).format() : '',
            endDate: endDate ? moment.utc(endDate).format() : ''
          },
          { ...environment.params }
        );

        toast.success('Report successfully scheduled');
        setRowData([
          ...rowData,
          {
            scheduledReportId: res.data.scheduledReportId,
            reportName,
            queryName: query.queryName,
            frequency,
            startDate,
            endDate,
            enabled: true
          }
        ]);
      } catch (err: AxiosError | any) {
        toast.error(err.response.data.error);
      } finally {
        setOpen(false);
        setLoading(false);
        clearForm();
      }
    };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(`${environment.apiPath}reportQuery`, {
          ...environment.params
        });
        setReportQueries(res.data);
      } catch (err: AxiosError | any) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, []);

  return (
    <GenericModal
      btnTitle='Schedule'
      clearForm={clearForm}
      classes='btn--w-200-px btn--blue'
      endIcon={<img src={CalendarIcon} alt='Schedule report' />}
      formTitle='Schedule Orders Report'
      formError={
        !reportName ||
        !query ||
        startDateError ||
        endDateError ||
        !!reportNameError
      }
      handleSubmit={handleSubmit}
    >
      <ControlledInput
        id='reportName'
        label='Report name'
        type='text'
        value={reportName}
        placeholder='Type report name here'
        handleChange={handleReportNameChange}
        error={reportNameError}
        required
      />
      <Label
        id='search-query'
        control={
          <StyledFormControl>
            <StyledAutocomplete
              data-testid='select-query-testid'
              options={reportQueries}
              getOptionLabel={(option: any) => option.queryName}
              value={query || null}
              onChange={(event: any, value: any) => setQuery(value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder='Select report query'
                  variant='outlined'
                />
              )}
            />
          </StyledFormControl>
        }
        label='Query name'
        classes='label--w-100'
        required
      />
      <CustomDatePicker
        id='start-date'
        value={startDate}
        handleChange={(event: any) => setStartDate(event)}
        minDate={new Date()}
        label='Start date'
        onError={(error: any) => {
          setStartDateError(error);
        }}
      />
      <CustomDatePicker
        id='end-date'
        value={endDate}
        handleChange={(event: any) => setEndDate(event)}
        minDate={startDate || new Date()}
        label='End date'
        helperText={helperText}
        onError={(error: any) => {
          setEndDateError(error);
          setHelperText(
            error ? 'End date cannot be set before start date' : ''
          );
        }}
      />
      <Label
        id='frequency'
        control={
          <StyledFormControl variant={'outlined' as any}>
            <Select
              data-testid='select-frequency-testid'
              displayEmpty
              value={frequency}
              onChange={(event: any) => setFrequency(event.target.value)}
            >
              <MenuItem key='placeholder' value='' disabled>
                Select frequency
              </MenuItem>
              {reportFrequencies.map((fqy: string) => (
                <MenuItem key={fqy} value={fqy}>
                  {fqy.toLocaleUpperCase()}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        }
        label='Frequency'
        classes='label--w-100'
        required
      />
    </GenericModal>
  );
};

export default ScheduleReportModal;
