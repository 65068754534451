import { Switch, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';

import StyledSwitch from './styles';

interface ICustomSwitchProps {
  checked: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  offLabel: string;
  onLabel: string;
  className?: string;
}

const CustomSwitch: React.FC<ICustomSwitchProps> = ({
  checked,
  handleChange,
  offLabel,
  onLabel,
  className
}) => (
  <StyledSwitch>
    <Typography className={className}>{offLabel}</Typography>
    <Switch checked={checked} onChange={handleChange} size='medium' />
    <Typography className={className}>{onLabel}</Typography>
  </StyledSwitch>
);

CustomSwitch.defaultProps = {
  className: ''
};

export default CustomSwitch;
