import React, { SyntheticEvent, useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import ViewMoreIcon from '../../../../../assets/icons/view-more.svg';

import environment from '../../../../../environment';
import NewProductIcon from '../../../../../assets/icons/new-product.png';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';
import GenericModal from '../../../../../components/GenericModal';
import StyledSkuList from './styles';
import { Button, Chip } from '@mui/material';

const BulkPreorderReleaseModal: React.FC<{
  id: string;

  rowData: ISkuGroup[];
}> = ({ id, rowData }) => {
  const [expanded, setExpanded] = useState(false);
  const overflowingText = useRef<HTMLDivElement | null>(null);
  const [viewMoreBtnVisible, setViewMoreBtnVisible] = useState(false);
  const group = rowData.filter((item: ISkuGroup) => item.id === id);
  const [preordersProductCodes, setPreordersProductCodes] = useState<string[]>(
    []
  );

  useEffect(() => {
    if (group.length > 0) {
      setPreordersProductCodes(
        group[0].canReleasePreordersList
          .filter((item) => item.canReleasePreorders === true)
          .map((item) => item.productCode)
      );
    }
  }, [group]);

  const checkProductCodesOverflow = (container: HTMLDivElement | null) => {
    if (container) {
      if (expanded) {
        setViewMoreBtnVisible(container.offsetHeight > 100);
      } else {
        setViewMoreBtnVisible(container.offsetHeight < container.scrollHeight);
      }
    }
  };

  useEffect(() => {
    checkProductCodesOverflow(overflowingText.current);
  }, [preordersProductCodes]);

  if (!group || !group[0].canReleasePreordersList) {
    return null;
  }

  const isPreorderInGroup = group[0].canReleasePreordersList.some(
    (item) => item.canReleasePreorders === true
  );

  if (!isPreorderInGroup) {
    return null;
  }

  const countPreorders = group[0].canReleasePreordersList.filter(
    (item) => item.canReleasePreorders === true
  ).length;

  const countAllProducts = group[0].products.length;

  const warnings: string[] = [
    'Pre-orders will be released based on the stock availability of each SKU'
  ];

  const handleSubmit =
    (
      setLoading: (loading: boolean) => void,
      setOpen: (open: boolean) => void
    ) =>
    async (event: SyntheticEvent) => {
      event.preventDefault();
      setLoading(true);

      try {
        const res = await axios.post(
          `${environment.apiPath}releasePreorders`,
          {
            productCodes: preordersProductCodes,
            subinventory: 'MULTICHANL'
          },
          { ...environment.params }
        );
        toast.success('Successfully released pre-orders for SKUs in SKU Group');
      } catch (err: AxiosError | any) {
        const errorMessage =
          err.response?.data?.error ||
          err.message ||
          'An unexpected error occurred';

        toast.error(errorMessage);
      } finally {
        setOpen(false);
        setLoading(false);
      }
    };

  return (
    <GenericModal
      iconBtn={NewProductIcon}
      formSubtitle={
        <h3>{`Please confirm you want to release the pre-orders for the
          SKUs mentioned below (${countPreorders} out of ${countAllProducts}): `}</h3>
      }
      warnings={warnings}
      handleSubmit={handleSubmit}
    >
      <StyledSkuList expanded={expanded}>
        <div
          ref={overflowingText}
          className={
            'product-codes-container product-codes--ml-50 product-codes--justify-center'
          }
        >
          {preordersProductCodes.map((product: string) => (
            <Chip key={product} label={product} />
          ))}
        </div>
        {viewMoreBtnVisible && (
          <Button
            onClick={() => setExpanded(!expanded)}
            className={
              'view-more-button product-codes--ml-50 product-codes--justify-center'
            }
          >
            <span style={{ color: 'white' }}>
              {expanded ? 'View less' : 'View more'}
            </span>
            <img src={ViewMoreIcon} alt='ViewMore' />
          </Button>
        )}
      </StyledSkuList>
    </GenericModal>
  );
};

export default BulkPreorderReleaseModal;
