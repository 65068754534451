import React, { ChangeEvent } from 'react';

import StyledTextField from './styles';
import Label from '../Label';

interface IControlledInputProps {
  id: string;
  type: string;
  label: string;
  value: string | number;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  maxLen?: number;
  required?: boolean;
  placeholder?: string;
  classes?: string;
  disabled?: boolean;
  error?: string;
}

const ControlledInput: React.FC<IControlledInputProps> = ({
  id,
  type,
  label,
  value,
  required,
  placeholder,
  handleChange,
  maxLen,
  classes,
  disabled,
  error
}) => (
  <>
    <Label
      id={id}
      control={
        <StyledTextField
          error={!!error}
          variant={'outlined' as any}
          type={type}
          placeholder={placeholder}
          inputProps={{
            id,
            shrink: 'false',
            min: 0,
            max: 999999,
            maxLength: maxLen
          }}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          helperText={error}
        />
      }
      label={label}
      classes={classes}
      required={required}
    />
  </>
);

ControlledInput.defaultProps = {
  required: false,
  placeholder: '',
  maxLen: 100,
  classes: '',
  disabled: false,
  error: ''
};

export default ControlledInput;
