import styled from 'styled-components';

export default styled.footer`
  height: 66px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  color: ${(props) => props.theme.colors.white};
  font-size: 18px;
  display: flex;
  align-items: center;
`;
