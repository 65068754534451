import { CognitoUser } from '@aws-amplify/auth';

export default class User {
  public userData: { [id: string]: any };

  constructor(public cognitoUser: CognitoUser) {
    // get user claims from the id token
    this.userData = this.cognitoUser;
  }

  get groups(): string[] {
    return this.userData['cognito:groups'] || [];
  }

  get attributes(): { [id: string]: any } {
    return this.userData || {};
  }

  get name(): string {
    return this.userData.username.split('_')[1];
  }

  get email(): string {
    return this.userData.email;
  }
}
