import React from 'react';

import StyledControls from './styles';
import RemoveSkuGroupModal from '../RemoveSkuGroupModal';
import BulkPreorderReleaseModal from '../BulkPreorderReleaseModal';
import SkuGroupBulkChangesModal from '../SkuGroupBulkChangesModal';
import { ISkuGroup } from '../../../../../interfaces/ISkuGroup';
import { IUserPermissions } from '../../../../../interfaces/IAuthState';

interface ControlButtonsProps {
  permissions: IUserPermissions;
  setRowData: (data: ISkuGroup[]) => void;
  rowData: ISkuGroup[];
  data: ISkuGroup;
}

const ControlButtons: React.FC<ControlButtonsProps> = ({
  permissions,
  setRowData,
  rowData,
  data
}) => {
  const { id, skuGroup } = data;

  return (
    <StyledControls>
      <SkuGroupBulkChangesModal id={id} skuGroup={skuGroup} />
      <RemoveSkuGroupModal
        id={id}
        skuGroup={skuGroup}
        setRowData={setRowData}
        rowData={rowData}
      />
      {permissions.viewBulkReleasePreordersButton && (
        <BulkPreorderReleaseModal id={id} rowData={rowData} />
      )}
    </StyledControls>
  );
};

export default ControlButtons;
