import styled from 'styled-components';
import { Dialog } from '@mui/material';

export default styled(Dialog)`
  .close-btn {
    display: flex;
    justify-content: flex-end;
    padding: 24px;
  }

  .dialog-confirmation {
    align-self: center;
    text-align: center;
    font-weight: normal;
    font-size: 40px;
    color: white;
    margin-top: 10%;
    margin-bottom: 35px;
    max-width: 50%;
  }

  .dialog-title {
    font-size: 56px !important;
    line-height: 62px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  h3 {
    font-size: 28px;
    margin: 0;
    margin-bottom: 35px;
    font-family: Vodafone Lt;
    color: white;
    text-align: center;
  }

  hr {
    width: 100%;
  }

  .dialog-subtitle {
    background-color: ${(props) => props.theme.colors.darkGrey};
    border-bottom: 1px solid ${(props) => props.theme.colors.shadowGrey};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 47px;

    p {
      color: ${(props) => props.theme.colors.white};
      font-family: Vodafone Rg;
      font-size: 20px;
      line-height: 22px;
      margin: 0;
    }
  }

  .dialog-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .dialog-content__form {
      display: flex;
      flex-direction: column;
      width: 75%;
      max-width: 1200px;

      h4 {
        font-size: 22px;
        color: white;
        margin: 10px 0px 0px;
      }

      & > * {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .MuiInputLabel-root p {
        margin-bottom: 0;
      }

      .dialog-content__form__release-warning {
        color: ${(props) => props.theme.colors.yellow};
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .dialog-btn--container {
      display: flex;
      gap: 20px;
    }
  }
`;
