import styled from 'styled-components';

export default styled.div`
  display: flex;
  margin-top: 20px;

  .error__icon {
    background-color: ${(props) => props.theme.colors.yellow};
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    display: flex;
    padding: 20px;
  }

  .error__details {
    display: flex;
    justify-content: space-between;
    background-color: ${(props) => props.theme.colors.darkGrey};
    border: 1px solid ${(props) => props.theme.colors.yellow};
    width: 100%;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px;

    .error__details__description {
      max-width: 75%;

      h3 {
        font-size: 22px;
        margin: 5px 0;
        color: ${(props) => props.theme.colors.white};
      }
    }

    p {
      margin: 5px 0;
      color: ${(props) => props.theme.colors.white};
    }
  }
`;
