import React, { ChangeEvent } from 'react';
import { Checkbox } from '@mui/material';

import StyledFormControlLabel from './styles';

interface IControlledCheckboxProps {
  checked: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  label: string;
  disabled?: boolean;
}

const ControlledCheckbox: React.FC<IControlledCheckboxProps> = ({
  checked,
  handleChange,
  label,
  disabled
}) => (
  <StyledFormControlLabel
    control={
      <Checkbox
        checked={checked}
        onChange={handleChange}
        data-testid='checkbox-testid'
        inputProps={{
          // @ts-ignore
          'data-testid': 'checkbox-input-testid'
        }}
        disabled={disabled}
      />
    }
    label={label}
  />
);

ControlledCheckbox.defaultProps = {
  disabled: false
};

export default ControlledCheckbox;
