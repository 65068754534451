import React from 'react';

import DeliveryIcon from '../../../../assets/icons/stock.svg';
import GenericTitle from '../../../../components/GenericSection/components/GenericTitle';
import { IDeliveryGroup } from '../../../../interfaces/IOrder';
import DeliveryGroup from '../DeliveryGroup/DeliveryGroup';
import { IUserPermissions } from '../../../../interfaces/IAuthState';

const DeliveryGroupList: React.FC<{
  orderID: string;
  deliveryGroups: IDeliveryGroup[];
  clearData: CallableFunction;
  returnReferenceCode?: string;
  asnFile?: string;
  permissions: IUserPermissions;
  orderSubinventory: string;
}> = ({
  orderID,
  deliveryGroups,
  clearData,
  returnReferenceCode,
  asnFile,
  permissions,
  orderSubinventory
}) => (
  <>
    <GenericTitle
      img={DeliveryIcon}
      altText='Delivery'
      title='Delivery groups'
    />

    {deliveryGroups.map((deliveryGroup: IDeliveryGroup) => (
      <DeliveryGroup
        key={deliveryGroup.number}
        orderSubinventory={orderSubinventory}
        orderID={orderID}
        group={deliveryGroup}
        clearData={clearData}
        returnReferenceCode={returnReferenceCode}
        asnFile={asnFile}
        permissions={permissions}
      />
    ))}
  </>
);

DeliveryGroupList.defaultProps = {
  returnReferenceCode: '',
  asnFile: ''
};

export default DeliveryGroupList;
