import { Container } from '@mui/material';
import styled from 'styled-components';

export default styled(Container)`
  .reporting__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .reporting__grid {
    height: 600px;
    width: 100%;
    margin: 30px 0;
    font-size: 14px;
    font-family: Vodafone Lt;
  }

  .MuiIconButton-root {
    padding: 0;
  }

  .disabled-row {
    background-color: ${(props) => props.theme.colors.lightCoral};
    color: ${(props) => props.theme.colors.shadowGrey};
  }
`;
