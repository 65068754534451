import React, { useEffect, useState } from 'react';
import moment from 'moment';
import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';

import GenericTitle from '../../../../../components/GenericSection/components/GenericTitle';
import AuditIcon from '../../../../../assets/icons/audit.svg';
import StyledAuditTrail from './styles';
import AuditStep from '../AuditStep';
import environment from '../../../../../environment';

interface IAudit {
  system: string;
  filename: string;
  timestamp: string;
  operation: string;
  channels: any[];
}

interface IAuditTrail {
  date: string;
  audit: IAudit[];
}

const AuditTrail: React.FC<{
  productCode: string;
  onAuditChange: (lastDate: string) => void;
}> = ({ productCode, onAuditChange }) => {
  const [auditTrail, setAuditTrail] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get(
          `${environment.apiPath}product/${productCode}/audit`,
          {
            ...environment.params
          }
        );

        const data = res.data;
        setAuditTrail(
          data.reduce((trail: IAuditTrail[], currAudit: IAudit) => {
            const idx = trail.findIndex((p) =>
              moment(p.date).isSame(currAudit.timestamp, 'day')
            );

            if (idx === -1) {
              trail.push({ date: currAudit.timestamp, audit: [currAudit] });
            } else {
              trail[idx].audit.push(currAudit);
              trail[idx].audit.sort((a1, a2) =>
                moment(a1.timestamp).isAfter(a2.timestamp) ? -1 : 1
              );
            }
            // sort the array in descending order
            trail.sort((a, b) => (moment(a.date).isAfter(b.date) ? -1 : 1));

            return trail;
          }, [])
        );

        onAuditChange(moment(data[data.length - 1].timestamp).format('ll'));
      } catch (err: AxiosError | any) {
        toast.error(err.message);
      }
    };

    fetchData();
  }, [productCode]);

  return (
    <StyledAuditTrail>
      <GenericTitle img={AuditIcon} altText='Audit' title='Audit trail' />

      {auditTrail.map((trailStep: IAuditTrail) => (
        <div key={trailStep.date}>
          <div className='trail-column'>
            <p className='trail-column__timestamp trail-column__timestamp--regular'>
              {moment(trailStep.date).format('ll')}
            </p>
            <div className='bar' />
          </div>
          {trailStep.audit.map((a: IAudit) => (
            <AuditStep audit={a} key={a.filename} />
          ))}
        </div>
      ))}
    </StyledAuditTrail>
  );
};

export default AuditTrail;
