import styled from 'styled-components';

const StyledLink = styled.button`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;

  &:focus {
    outline: 0;
  }

  span:hover {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }
`;

const StyledList = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  width: 60% !important;

  pre {
    font-family: Vodafone Lt;
  }

  & > * {
    margin: 0 0 0 0;
  }
`;

export { StyledLink, StyledList };
