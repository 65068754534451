import styled from 'styled-components';

export default styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: ${(props) => props.theme.colors.white};
      font-size: 22px;
      margin: 5px;
    }

    hr {
      color: ${(props) => props.theme.colors.white};
      width: 100%;
      margin: 20px 0;
    }
  }
`;
