import styled from 'styled-components';

export default styled.div`
  .trail-column {
    min-width: 100px;
    width: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .trail-column__timestamp {
      color: ${(props) => props.theme.colors.shadowGrey};

      &.trail-column__timestamp--regular {
        font-family: Vodafone Rg;
      }

      &.trail-column__timestamp--small {
        font-size: 16px;
      }
    }
  }

  .bar {
    width: 3px;
    background-color: ${(props) => props.theme.colors.grey};
    height: 20px;
  }

  .MuiAccordion-root {
    margin: 0 !important;
  }
`;
