import styled from 'styled-components';
import { Rating } from '@mui/material';

export default styled(Rating)`
  && {
    display: flex;
    align-items: center;
    width: fit-content;
  }

  &.rating--white {
    color: ${(props) => props.theme.colors.white};
  }

  &.rating--light-grey {
    color: ${(props) => props.theme.colors.lightGrey};
  }

  &.rating--silver-grey {
    color: ${(props) => props.theme.colors.silverGrey};
  }

  &.rating--grey {
    color: ${(props) => props.theme.colors.grey};
  }

  &.rating--shadow-grey {
    color: ${(props) => props.theme.colors.shadowGrey};
  }

  &.rating--dark-grey {
    color: ${(props) => props.theme.colors.darkGrey};
  }

  &.rating--black {
    color: ${(props) => props.theme.colors.black};
  }

  &.rating--light-red {
    color: ${(props) => props.theme.colors.lightRed};
  }

  &.rating--vodafone-red {
    color: ${(props) => props.theme.colors.vodafoneRed};
  }

  &.rating--dark-red {
    color: ${(props) => props.theme.colors.darkRed};
  }

  &.rating--light-blue {
    color: ${(props) => props.theme.colors.lightBlue};
  }

  &.rating--opac-blue {
    color: ${(props) => props.theme.colors.opacBlue};
  }

  &.rating--blue {
    color: ${(props) => props.theme.colors.blue};
  }

  &.rating--purple {
    color: ${(props) => props.theme.colors.purple};
  }

  &.rating--light-purple {
    color: ${(props) => props.theme.colors.lightPurple};
  }

  &.rating--yellow {
    color: ${(props) => props.theme.colors.yellow};
  }

  &.rating--green {
    color: ${(props) => props.theme.colors.green};
  }

  &.rating--light-green {
    color: ${(props) => props.theme.colors.lightGreen};
  }

  &.rating--light-coral {
    color: ${(props) => props.theme.colors.lightCoral};
  }
`;
